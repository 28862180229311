import { useEffect, useState } from 'react';
import './index.scss'
import anime from 'animejs';


const Anim =() =>{
  return(
    <div className='scss11 absolute grid h-screen w-56 right-96 place-content-center bg-slate-1000 px-8' >
      <DotGrid/>
    </div>
  )
}

  

const WIDTH=30
const HEIGHT=12

const DotGrid =()=>{
  const handleDotClick = (e: any)=>{
    anime({
      targets:".dot-point",
      scale:[
        {value:3,easing:"easeOutSine",duration:250},
        {value:1,easing:"easeInOutQuad",duration:500}
      ],
      translateY:[
        {value:-30,easing:"easeOutSine",duration:250},
        {value:0,easing:"easeInOutQuad",duration:500}
      ],
      opacity:[
        {value:5,easing:"easeOutSine",duration:250},
        {value:0.5,easing:"easeInOutQuad",duration:500}
      ],
      delay: anime.stagger(75,{
        grid:[WIDTH,HEIGHT],
        from:e.target.dataset.index,
      }),
    })
  }
  
  const dots =[]
  let index =0
  for (let i=0;i<WIDTH;i++){
    for(let j=0;j<HEIGHT;j++){
      dots.push(
        <div
        onClick={handleDotClick}
        className="group cursor-crosshair rounded-full p-6 transition-colors hover:bg-slate-600"
         data-index={index}
         key={`${i}-${j}`}
         >
          <div className='dot-point h-7 w-3 rounded-full bg-gradient-to-b from-slate-700 to-slate-400 opacity-50 group-hover:from-green-900 group-hover:to-yellow-900'
          data-index={index}
          />
        </div>
      );
      index++
    }
  }

  return(
  <div
  style={{gridTemplateColumns: `repeat(${WIDTH},1fr)`}}
   className="grid w-fit"
  >{dots}</div>
  )
}

export default Anim