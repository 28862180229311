import LogoTitle from '../../assets/images/mlogowood.png';
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import './index.scss'
import Anim from '../Anim';





const Home = () => {
  const [letterClass, setLetterClass]=useState('text-animate')
  const nameArray = ['e','h','r','a','b']
  
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLetterClass('text-animate-hover');
    }, 2500);
  
    return () => clearTimeout(timeoutId);
  }, [setLetterClass]);
  
  
  return(
    <>
    
    <div className='container home-page'>
      <div className='text-zone'>
       
       <h1>
        <span className={letterClass}>H</span>
        <span className={`${letterClass} _12`}>i,</span>
        <br/>
        <span className={`${letterClass} _13`}>I</span>
        <span className={`${letterClass} _14`}>'m</span>
        
        <img className='grid' src={LogoTitle} alt='dev'/>
        <AnimatedLetters
              letterClass={letterClass}
              strArray={nameArray}
              idx={15}
            />
        <br/>
        </h1>
        <h2>Fullstack Developer</h2>
        <Link to="/contact" className='flat-button'>CONTACT ME </Link>
        <p className='exp'>For best experience, use desktop</p>
        
      </div>
     
      <Anim />
      
      
      
    </div>
    <Loader type="pacman" />
    </>
  );
}


export default Home